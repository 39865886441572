<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row :gutter="10">
                    <a-col :span="2">
                        <a-date-picker mode="year"
                                       format="YYYY"
                                       :allowClear="false"
                                       :value="currentYear"
                                       @openChange="isOpen = true"
                                       :open="isOpen"
                                       style="width: 100%;"
                                       @panelChange="yearChange"/>
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                                style="width: 100%"
                                allowClear
                                multiple
                                @change="handleCompanyChange"
                                :placeholder="l('Company')"
                                tree-default-expand-all
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="companyTreeList"
                                v-model="comPanyId"
                        />
                    </a-col>
<!--                    <a-col :span="4">-->
<!--                        <a-tree-select-->
<!--                                style="width: 100%"-->
<!--                                multiple-->
<!--                                allowClear-->
<!--                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--                                :tree-data="DepTreeData"-->
<!--                                :placeholder="l('Department')"-->
<!--                                tree-default-expand-all-->
<!--                                v-model="departmentId"-->
<!--                                @change="getData"-->
<!--                        >-->
<!--                        </a-tree-select>-->
<!--                    </a-col>-->
                    <a-col :span="18" style="text-align: left">
                        <a-divider type="vertical" v-if="isGranted('sl_struct_excel')" />
                        <a-button type="primary" @click="Export()" v-if="isGranted('sl_struct_excel')">
                            <a-icon type="download" />
                            <span>导出</span>
                        </a-button>
                    </a-col>
                </a-row>
                <a-row :gutter="[20,10]">
                    <a-col :span="10">
                        <a-table
                                :columns="columns"
                                :pagination="false"
                                :scroll="{ y: scroll_y,x:800}"
                                :rowKey="(data,index) => index"
                                :dataSource="tableData"
                        >
                        </a-table>
                        <a-pagination
                                class="pagination"
                                :total="totalItems"
                                v-model="pageNumber"
                                :showTotal="showTotalFun"
                                @change="onChangePage"
                                @showSizeChange="showSizeChange"
                        />
                    </a-col>
                    <a-col :span="14" style="overflow-x: auto">
                        <div id="report" :style="{'height':scroll_y+'px'}"></div>
                    </a-col>
                </a-row>
            </a-card>
            <div
                    v-if="isOpen"
                    :style='{
                    "height": windowHeight + "px",
                    "position":"absolute",
                    "top":"0px",
                    "width":"100%"
                 }'
                    @click="isOpen=false">
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN"
    import {
        CompanyServiceProxy,
        DepartmentServiceProxy,
        SalaryReportServiceProxy
    } from "../../../../shared/service-proxies";
    import {fileDownloadService} from "../../../../shared/utils";
    import DepTreeUntils from "../../../organization/department/dep-tree-untils/dep-tree-untils";
    import moment from "moment";
    import * as echarts from "echarts";

    export default {
        name: "salary-struct",
        mixins:[AppComponentBase],
        data(){
            return{
                zh_CN,
                //表格上方间隔
                defaultTop: -80,
                isOpen:false,
                currentYear: undefined,
                companyTreeList: [], //公司
                comPanyId: undefined, //公司id
                departmentId: undefined,
                //部门树
                DepTreeData: [],
                DepTree: [],
                // 用户表格
                columns: [
                    {
                        title: '公司',
                        dataIndex: 'companyName',
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'companyName'},
                    },
                    {
                        title: '部门',
                        dataIndex: 'department',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'department'},
                    },
                    {
                        title: '基本工资',
                        dataIndex: 'basicWage',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'basicWage'},
                    },
                    {
                        title: '津贴',
                        dataIndex: 'allowanceSubtotal',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'allowanceSubtotal'},
                    },
                    {
                        title: '奖金',
                        dataIndex: 'bonusSubtotal',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'bonusSubtotal'},
                    },
                    {
                        title: '加班费',
                        dataIndex: 'oTCostSubtotal',
                        sorter: false,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'oTCostSubtotal'},
                    }
                ],
                tableData: [],
                totalItems: 0, //总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ['10', '20', '30', '40'],
                request: {maxResultCount: 10, skipCount: 0, sorting: ''},
            }
        },
        computed:{
            windowHeight(){
                return window.innerHeight-150;
            }
        },
        created() {
            // 公司
            this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
            //部门
            this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
            this._salaryReportServiceProxy = new SalaryReportServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        mounted() {
            this.currentYear = moment();
            this.initCompany();
            this.refresh();
        },
        methods:{
            refresh() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            getData(){
                this.loading=true;
                this._salaryReportServiceProxy.getSalaryStruct(
                    this.comPanyId ? _.join(this.comPanyId, ",") : undefined,
                    this.departmentId ? _.join(this.departmentId, ",") : undefined,
                    this.currentYear.year(),
                    undefined,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(()=>{this.loading=false})
                .then(res=>{
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);

                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                    this.reportInit();
                });
            },
            Export(){
                if(this.tableData.length<=0){
                    return abp.message.warn(this.l('NoData'))
                }
                this.loading=true;
                this._salaryReportServiceProxy.getSalaryStructToExcel(
                    this.comPanyId ? _.join(this.comPanyId, ",") : undefined,
                    this.departmentId ? _.join(this.departmentId, ",") : undefined,
                    this.currentYear.year(),
                    undefined,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(()=>{this.loading=false})
                .then(res=>{
                    this._fileDownloadService.downloadTempFile(res);
                });
            },
            /**
             * 格式化数据
             */
            reportDataInit() {
                let eData = {
                    xAxis: [],
                    basicWage: [],
                    bonusSubtotal: [],
                    allowanceSubtotal: [],
                    otCostSubtotal:[]
                };
                this.tableData.forEach((item, index) => {
                    eData.xAxis.push(item.department);
                    eData.basicWage.push(item.basicWage);
                    eData.bonusSubtotal.push(item.bonusSubtotal);
                    eData.allowanceSubtotal.push(item.allowanceSubtotal);
                    eData.otCostSubtotal.push(item.otCostSubtotal);
                });
                return eData;
            },
            /**
             * Eachart dom渲染
             */
            reportInit(){
                let chartDom = document.getElementById('report');
                var data=this.reportDataInit();
                let myChart = echarts.init(chartDom);
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: ['基本工资', '津贴', '奖金', '加班费']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: data.xAxis
                    },
                    series: [
                        {
                            name: '基本工资',
                            type: 'bar',
                            stack: 'total',
                            emphasis: {
                                focus: 'series'
                            },
                            data: data.basicWage
                        },
                        {
                            name: '津贴',
                            type: 'bar',
                            stack: 'total',
                            emphasis: {
                                focus: 'series'
                            },
                            data: data.allowanceSubtotal
                        },
                        {
                            name: '奖金',
                            type: 'bar',
                            stack: 'total',
                            emphasis: {
                                focus: 'series'
                            },
                            data: data.bonusSubtotal
                        },
                        {
                            name: '加班费',
                            type: 'bar',
                            stack: 'total',
                            emphasis: {
                                focus: 'series'
                            },
                            data: data.otCostSubtotal
                        }
                    ]
                };
                myChart.setOption(option);
                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            },
            //初始化公司
            initCompany() {
                this._companyConfigServiceProxy
                    .getPaged(undefined, '', 100, 0)
                    .finally(() => {
                        // this.spinning = false;
                    })
                    .then((res) => {
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName,
                                };
                                this.companyTreeList.push(obj);
                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                        // if (this.companyTreeList.length > 0) {
                        //     this.handleCompanyChange(this.companyTreeList[0].key);
                        // }
                    });
            },
            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName,
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            //选择公司
            handleCompanyChange(value) {
                this.comPanyId = value;
                this.refresh();
                this.departmentId = undefined;
                // this.getDepTreeData();
            },
            /**
             * 获取部门
             */
            getDepTreeData() {
                this.DepTreeData = [];
                this._departmentServiceProxy
                    .getDepTreeData(this.comPanyId)
                    .then((res) => {
                        this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
                        this.DepTree.forEach((item) => {
                            if (!item.parentId) {
                                this.DepTreeData.push(item);
                            }
                        });
                        this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
                    })
                    .finally(() => {
                        // this.loading = false;
                    });
            },
            //年选择器改变
            yearChange(e) {
                this.isOpen = false;
                this.currentYear = e;
                this.refresh();
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    'GridFooterDisplayText',
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * 分页
             */
            onChangePage(page, pageSize) {
                this.selectedRowKeys = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.selectedRowKeys = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
                } else {
                    this.request.sorting = undefined;
                }
                this.refresh();
            },
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
